import { Link } from 'gatsby';
import React from 'react';
import { menu } from '../constants/menu';
import { socialLinks } from '../constants/socialLinks';
import { FooterStyles } from '../styles/FooterStyles';
import Logo from './Logo';
import ParagraphText from './typography/ParagraphText';

function Footer() {
  return (
    <FooterStyles>
      <div className="container">
        <Logo />
        <ParagraphText className="footer__text">
          Thank you for considering ENERGY BROS as your trusted partner in
          resolving billing challenges. With our unwavering commitment to
          accuracy and efficiency, we strive to provide tailored solutions that
          exceed your expectations. Feel free to reach out to us for
          unparalleled expertise and personalized service. Here's to a brighter,
          error-free future together.
        </ParagraphText>
        <ul className="footer__menuList">
          {menu.map((item) => (
            <li key={item.path}>
              <Link to={item.path}>{item.title}</Link>
            </li>
          ))}
        </ul>
        <ul className="footer__socialList">
          {socialLinks.map((item) => (
            <li key={item.name}>
              <a href={item.url}>{item.icon}</a>
            </li>
          ))}
        </ul>
        <ParagraphText className="copyright">
          6A Diaz Avenue, Edenvale, Johannesburg 1609
        </ParagraphText>
        <ParagraphText className="copyright">
          © Energy Bros (PTY) LTD | {new Date().getFullYear()}| All Rights
          Reserved
        </ParagraphText>
      </div>
    </FooterStyles>
  );
}

export default Footer;
