export const menu = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Our Work',
    path: '/spotlight',
  },
  {
    title: 'Services',
    path: '/services',
  },
  {
    title: 'Our Team',
    path: '/team',
  },
];
